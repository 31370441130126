<template>
  <b-container fluid>
    <b-row >
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ count }} Groups</h4>
          </template>
          <template v-slot:headerAction>
            <div class="d-flex align-items-center">
              <div class="input-with-icon mr-3">
                <span class="icon is-small is-left" style="margin-top: 35px;">
                  <i class="fas fa-search"></i>
                </span>
                <input type="search" class="form-control" v-model="searchQuery" @input="handleSearch" placeholder="Search" >
              </div>
              <b-button variant="primary" @click="openModal" style="border-radius: 5px">Add Group</b-button>
            </div>
          </template>
          
          <template v-slot:body>
            <b-row v-if="loading">
      <LoadingSpinner :loading="loading" />
    </b-row>
            <b-row v-else>
              <b-col md="12" v-if="!rows.length" class="table-responsive">
                <h5 class="text-center mt-4">{{ response_message }}</h5>
              </b-col>
              <b-col md="12" v-else class="table-responsive">
                <b-table bordered hover :items="rows" :fields="columns" :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc">
                  <template v-slot:cell(serialNumber)="data">
                    {{ (currentPage - 1) * itemsPerPage + data.index + 1 }}
                  </template>
                  <template v-slot:cell(action)="data">
                    <div class="text-left">
                      <button fdprocessedid="epulve" title="Scan" type="button"
                        class="btn btn btn-success btn-secondary btn-sm"
                        style="padding: 4px !important; border-radius: 3px !important; margin-right: 8px; margin-top: 5px;"><i
                          class="fas fa-search " style="margin-left: 5px !important;"
                          @click="handleButtonClick(data.item.public_id)"></i></button>
                      <b-button :disabled="data.item.is_start === true" variant="info" size="sm"
                        @click="edit(data.item)"
                        style="padding: 4px !important; border-radius: 3px !important; margin-right: 8px; margin-top:5px"
                        title="Edit">
                        <i class="fas fa-pencil-alt" style="margin-left: 5px !important;"></i>
                        <!-- Edit font icon -->
                      </b-button>
                      <!-- <button title="Stop" class="btn btn-success mb-1" fdprocessedid="epulve"><i class="fas fa-stop"></i></button> -->
                      <b-button :disabled="data.item.is_start === true" variant="danger" size="sm"
                        @click="remove(data.item.public_id)"
                        style="padding: 4px !important; border-radius: 3px !important; margin-right: 8px; margin-top:5px"
                        title="Delete">
                        <i class="fas fa-trash-alt" style="margin-left: 5px !important;"></i>
                        <!-- Delete font icon -->
                      </b-button>
                    </div>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </template>
          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="itemsPerPage" @change="onPageChange"
            align="right" class="p-3"  v-if="rows.length"/>
        </iq-card>
      </b-col>
    </b-row>
    <!-- Add/Edit Modal -->
    <b-modal v-model="modalOpen" :title="editMode ? 'Edit Group' : 'Add  Group'"
      :ok-title="editMode ? 'Update' : 'Save'" @ok="submitForm" no-close-on-backdrop no-close-on-esc
      @hide="checkModalClose" :disabled="isSubmitting">
      <b-form @submit.stop.prevent="submitForm" :valid="isFormValid">
        <b-form-group label="Name" label-for="nameInput" :state="nameState" invalid-feedback="
              Name is important and should not be just a number">
          <b-form-input id="nameInput" @keydown="preventLeadingSpace($event, 'name')" maxlength="30"
            v-model="newGroups.name" style="border-color:lightgray; height:38px;" required></b-form-input>
        </b-form-group>
        <b-form-group label="Status" label-for="StatusInput">
          <div class="d-flex align-items-center mt-5">
            <!-- Toggle Switch for Status -->
            <div class="">
              <input type="checkbox" id="switch" v-model="newGroups.status" />
              <label for="switch">Toggle</label>
            </div>
          </div>
        </b-form-group>
      </b-form>
    </b-modal>
  </b-container>
</template>
<script>
import axios from "axios";
import constant, { decryptToken, response_message, toastMessage } from "@/config/constant";
import LoadingSpinner from "@/config/Pageloader.vue";
import { core } from "../../../config/pluginInit";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { BPagination } from "bootstrap-vue";
import Swal from "sweetalert2";
export default {
  name: "Groups",
  components: {
    Treeselect,
    BPagination,
    LoadingSpinner
  },
  data() {
    return {
      isSubmitting: false, // Define isSubmitting in the data object
      columns: [
        { label: 'S. No.', key: 'serialNumber', class: 'text-left' },
        { label: "Name", key: "name", class: "text-left", sortable: true },
        { label: "Status", key: "is_active", class: "text-left", sortable: true },
        { label: "Action", key: "action", class: "text-left" },
      ],
      sortBy: '',
      sortDesc: false,
      searchQuery: "",
      response_message: response_message,
      preventModalClose: false,
      nameState: null,
      currentPage: 1,
      itemsPerPage: 20,
      totalRows: 0,
      message: "",
      icon: "",
      count: 0,
      rows: [], // Initialize the rows array to be empty initially
      modalOpen: false,
      editMode: false,
      newGroups: {
        name: "",
        status: false,
      },
      loading: true, // Add a loading state
    };
  },
  computed: {
    isFormValid() {
      return (
        this.nameState
      );
    },
  },
  methods: {
    preventLeadingSpace(event, field) {
      // First letter space not allowed
      if (event.key === ' ' && (event.target.selectionStart === 0 || this.newGroups[field] === '')) {
        event.preventDefault();
      } else {
        this.newGroups[field] = event.target.value.trim();
      }
    },
    handleSearch() {
      // Call fetchData with the searchQuery
      this.fetchData(this.currentPage, this.searchQuery);
      this.loading = true;
    },
    async handleButtonClick(public_id) {
      //group scan ip
      try {
        const token = localStorage.getItem("token");
        const key = "enc";
        const decryptedToken = decryptToken(token, key);
        const response = await axios.get(
          constant.API_BASE_URL +
          "vms/scan-ips/?public_id=" +
          public_id,
          {
            headers: { Authorization: "Token " + decryptedToken },
          }
        );

        window.location.reload();
      } catch (error) {
        console.error("Error fetching group data:", error);
      }
    },
    cancelForm() {
      this.modalOpen = false;
      this.clearForm(); // Optionally clear the form when canceling
    },

    async fetchData(pageNumber, searchQuery = "") {
      // Fetch group
      try {
        const token = localStorage.getItem("token");
        const key = "enc"; // Fetch encrypted token
        const decryptedToken = decryptToken(token, key); // Decrypt token
        const response = await axios.get(
          constant.API_BASE_URL + "vms/groups/", {
          params: {
            page: pageNumber,
            search: searchQuery,
            ordering: "-created_at"
          },
          headers: {
            Authorization: "Token " + decryptedToken,
          },
        }
        );
        this.count = response.data.count
        this.totalRows = response.data.count;
        this.rows = response.data.data;

        this.loading = false;
      } catch (error) {
        this.rows = [];
        this.loading = false;
      }
    },
    onPageChange(pageNumber) {
      this.currentPage = pageNumber;
      this.fetchData(pageNumber, this.searchQuery); // Fetch data for the new page
    },

    async submitForm() {
      // Group add edit
      try {
        this.nameState = this.newGroups.name ? true : false;
        if (this.isFormValid) {
          if (!/^(?=.*[a-zA-Z])[\w]+$/.test(this.newGroups.name)) {
            this.nameState = false;
            this.preventModalClose = true;
            return false;
          }
          this.isSubmitting = true; // Enable the submit button
          const token = localStorage.getItem("token");
          const key = "enc"; // Fetch encrypted token

          const decryptedToken = decryptToken(token, key); // Decrypt token
          const { name, status = false } =
            this.newGroups;

          const requestData = {
            name: name,
            is_active: status,
          };

          // Set the token in the request headers
          const headers = {
            Authorization: "Token " + decryptedToken,
          };

          let response;
          if (this.editMode) {
            // Edit mode
            response = await axios.put(
              constant.API_BASE_URL +
              "vms/group/" +
              this.newGroups.public_id +
              "/",
              requestData,
              { headers }
            );
          } else {
            // Add mode
            response = await axios.post(
              constant.API_BASE_URL + "vms/groups/",
              requestData,
              { headers }
            );
          }
          this.message = response.data.message
          // Set the icon to "success" for successful response
          this.icon = "success"
          // Call the toastMessage function to display a toast notification
          toastMessage(this.icon, this.message);
          await this.fetchData();
          this.modalOpen = false;
          this.clearForm();
        } else {
          this.preventModalClose = true;
          return false;
        }
      } catch (error) {
        // this.message=error.response.data.error.non_field_errors[0]
        if (error.response && error.response.data && error.response.data.message) {
          this.message = error.response.data.message;
        }
        else if (error.response && error.response && error.response.data) {
          this.message = error.response.data.error.non_field_errors;
        } else {
          this.message = "Something went wrong. Please try again later.";
        }
        // Set the icon to "success" for successful response
        this.icon = "error"
        // Call the toastMessage function to display a toast notification
        toastMessage(this.icon, this.message);

        await this.fetchData();
        this.modalOpen = false;
        this.clearForm();
        console.error("Error adding/editing group:", error);
      }
    },
    checkModalClose(event) {
      if (this.preventModalClose) {
        event.preventDefault(); // Prevent the modal from closing
        this.preventModalClose = false; // Reset the flag
      }
    },
    openModal() {
      this.modalOpen = true;
      this.editMode = false;
      this.clearForm();
      this.nameState = null;
      // this.isFormValid = false;
      this.message = "";
    },
    edit(item) {
      this.newGroups = { ...item };
      this.modalOpen = true;
      this.editMode = true;
      this.newGroups.status = item.is_active;
      this.nameState = null;
      this.message = "";
    },
    remove(public_id) {
      // Group delete
      try {
        let self = this;
        Swal.fire({
          toast: true,
          position: "top",
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",

        }).then((result) => {
          if (result.isConfirmed) {
            const token = localStorage.getItem("token");
            const key = "enc";
            const decryptedToken = decryptToken(token, key);
            axios.delete(
              constant.API_BASE_URL +
              "vms/group/" +
              public_id +
              "/",
              {
                headers: {
                  Authorization: "Token " + decryptedToken,
                },
              }
            )
              .then(response => {
                this.message = response.data.message
                // Set the icon to "success" for successful response
                this.icon = "success"
                // Call the toastMessage function to display a toast notification
                toastMessage(this.icon, this.message);

                self.fetchData();
                console.log("Successfully deleted:", response.data.message);
                // Do something with the response data if needed
              })
          }
        });
      } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
          this.message = error.response.data.message;
        } else {
          this.message = "Something went wrong. Please try again later.";
        }
        // Set the icon to "success" for successful response
        this.icon = "error"
        // Call the toastMessage function to display a toast notification
        toastMessage(this.icon, this.message);
        // console.error("Error deleting group:", error);
      }
    },
    cancelForm() {
      this.modalOpen = false;
      this.clearForm(); // Optionally clear the form when canceling
    },
    clearForm() {
      this.newGroups = {
        name: "",
      };
    },
  },
  mounted() {
    this.fetchData(this.currentPage);

    core.index();
  },
};
</script>
<style scoped>
input[type=checkbox] {
  height: 0;
  width: 0;
  visibility: hidden;
}

label {
  cursor: pointer;
  text-indent: -9999px;
  width: 60px;
  height: 30px;
  background: grey;
  display: block;
  border-radius: 30px;
  position: relative;
}

label:after {
  content: '';
  position: absolute;
  top: 5px;
  left: 5px;
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 30px;
  transition: 0.3s;
}

input:checked+label {
  background: #bada55;

}

input:checked+label:after {
  left: calc(100% - 5px);
  transform: translateX(-100%);
}

label:active:after {
  width: 100px;
}

</style>
